module.exports = {
    brandInfo: {
        phoneNumber: "18885364210",
        contactEmail: "info@ridetoday.ca",
        address: `4675-2785 Commercial Drive Vancouver BC`,
        copyright: `© ${new Date().getFullYear()} Ride Today`,
        defaultCampaignId: "228",
    },

    images: {
        icon: "images/logo.png",
    },

    project: "ride-today-credit-application",
    name: "Ride Today",
    description: `Get the best rates`,

    siteUrl: "https://www.ridetoday.ca",
    homeUrl: "/",
    termsUrl: "/terms_of_use",
    privacyPolicyUrl: "/privacy_policy",
    successUrl: "/success",

    hideSuccessCtas: true,
    twilioVerifyMethods: ["sms"],
};